<template>
  <v-container class="account-logout text-center pt-7 pb-6">
    <h1>{{ $t("LogoutHeader") }}</h1>

    <p class="pt-4 pb-4">{{ $t("LogoutText") }}</p>
    <div class="mt-4 mb-7">
      <v-btn rounded @click="cancelLogout" class="text-none">{{
        $t("Button.Cancel")
      }}</v-btn>
      <v-btn
        rounded
        color="primary"
        class="ml-4 text-none"
        @click="confirmLogout"
        >{{ $t("Logout") }}</v-btn
      >
    </div>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Logout": "Sign out",
    "LogoutText": "You can always log in again at any time.",
    "LogoutHeader": "Sign out from Babiry?"
    },
    "sv": {
    "Logout": "Logga ut",
    "LogoutText": "Du kan alltid logga in igen när du vill.",
    "LogoutHeader": "Vill du logga ut från Barndagboken?"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "account_logout",
  metaInfo() {
    return {
      title: this.$t("Logout"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  methods: {
    ...mapActions({
      accountLogout: "account/logout",
    }),
    confirmLogout() {
      this.accountLogout();
      this.$router.push("/");
    },
    cancelLogout() {
      this.$router.push("/account");
    },
  },
};
</script>