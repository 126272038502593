<template>
  <div class="account-messages">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Messages") }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        to="/account/messages/new"
        class="text-none text-subtitle-1"
        text
        color="primary"
      >
        {{ $t("NewMessage") }}</v-btn
      >
    </v-toolbar>

    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <template v-if="items && items.length > 0">
            <v-list two-line class="mt-5">
              <v-divider></v-divider>
              <template v-for="(item, index) in items">
                <v-list-item
                  :key="item.id"
                  :to="'/account/messages/' + item.id"
                >
                  <v-list-item-avatar>
                    <user-avatar :user="item.sender" :size="40"></user-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.subject | capitalize
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.sender.username | capitalize
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list>

            <v-pagination
              v-model="page"
              class="mt-4"
              :length="unit_pages"
              v-if="unit_pages > 1"
              :total-visible="7"
            ></v-pagination>
          </template>
          <template v-else>
            <div class="text-center mt-5 mb-5">{{ $t("NoMessages") }}</div>
          </template>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Messages": "Messages",
    "NoMessages": "There is no recived messages to show!",
    "NewMessage": "New message"
    },
    "sv": {
    "Messages": "Meddelanden",
    "NoMessages": "Det finns inga mottagna meddelanden att visa!",
    "NewMessage": "Nytt meddelande"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { userMessageService } from "../../../_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "account_messages",
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  components: {
    "user-avatar": UserAvatar,
  },
  data: () => ({
    items: [],
    is_loading: false,
    error: null,
    page: 1,
    unit_pages: 0,
  }),
  created: function () {
    this.setFromUrl();
    this.loadMessages();
  },
  methods: {
    loadMessages() {
      var self = this;
      self.is_loading = true;

      userMessageService
        .inbox(self.page)
        .then(function (response) {
          self.is_loading = false;
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    getMeta() {
      var obj = {
        title: this.$t("Messages"),
      };
      return obj;
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadMessages();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>