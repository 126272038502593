<template>
  <div class="account-email pt-5">
    <v-card-text class="text-center">
        <div>{{ $t("EmailSettingsDesc") }}</div>
        <div>
          {{ $t("CurrentEmail") }}
          <b>{{ user.email }}</b>
        </div>      
    </v-card-text>

    <v-card-text>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-row no-gutters>
          <v-col cols="12" md="8" xs="12" class="mx-auto">
            <v-text-field
              v-model="temp_user.email"
              type="email"
              required
              outlined
              dense
              :loading="is_saving"
              :disabled="is_saving"
              :rules="[
                (v) => !!v || $t('Validation.EmailRequired'),
                (v) => /.+@.+\..+/.test(v) || $t('Validation.EmailNotValid'),
              ]"
              :label="$t('Email')"
            >
              <template v-slot:append-outer>
                <v-btn
                  style="margin-top: -6px"
                  color="primary"
                  @click="save"
                  class="text-none"
                  :loading="is_saving"
                  >{{ $t("Button.Send") }}</v-btn
                >
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <error-box :error="error"></error-box>
            <v-alert
              text
              prominent
              color="teal"
              v-if="is_success"
              icon="$vuetify.icons.farCheckCircle"
              >{{ $t("SuccessSave") }}</v-alert
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "EmailSettings": "Mail-settings",
    "EmailSettingsDesc": "When change the email, you must confirm the new one by clicking the link sent to your new email address.",
    "Email": "New email",
    "CurrentEmail": "Your current email is:",
    "SuccessSave": "An email has been sent to the specified email address. Click on the link in this email to confirm your new email address."
    },
    "sv": {
    "EmailSettings": "Mail-inställningar",
    "EmailSettingsDesc": "När du väljer att ändra email måste du bekräfta den nya genom att klicka på länken som skickas ut till din nya mail-adress.",
    "Email": "Ny email",
    "CurrentEmail": "Nuvarande email är:",
    "SuccessSave": "Ett mail har skickats till den angivna mailadressen. Klicka på länken i detta mail för att bekräfta din nya mailadress."
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "../../../_services";

export default {
  name: "account_email",
  metaInfo() {
    return {
      title: this.$t("EmailSettings"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    form: false,
    error: null,
    is_saving: false,
    is_success: false,
    temp_user: {},
  }),
  methods: {
    save() {
      var sender = this;
      sender.error = null;
      sender.is_success = false;

      if (sender.$refs.form.validate()) {
        sender.is_saving = true;

        accountService
          .updateEmail({ email: sender.temp_user.email })
          .then(function () {
            sender.is_saving = false;
            sender.is_success = true;
          })
          .catch(function (error) {
            sender.is_saving = false;
            sender.error = error;
          });
      }
    },
  },
};
</script>