<template>
  <div class="account-password pt-5">
    <v-form ref="password_form" v-model="password_form" lazy-validation>
      <v-card-text>
        <v-text-field
          v-model="password.current_password"
          :append-icon="show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          :type="show1 ? 'text' : 'password'"
          required
          outlined
          :loading="is_saving_password"
          :disabled="is_saving_password"
          :rules="[rules.required]"
          :label="$t('CurrentPassword')"
          @click:append="show1 = !show1"
        ></v-text-field>

        <v-text-field
          v-model="password.new_password"
          :append-icon="show2 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          :type="show2 ? 'text' : 'password'"
          required
          outlined
          :loading="is_saving_password"
          :disabled="is_saving_password"
          :rules="[rules.required, rules.min]"
          :label="$t('NewPassword')"
          :hint="$t('PasswordHint')"
          @click:append="show2 = !show2"
        ></v-text-field>

        <v-text-field
          v-model="password.confirm_password"
          :append-icon="show3 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          required
          outlined
          :loading="is_saving_password"
          :disabled="is_saving_password"
          :rules="[rules.required, rules.passMatch]"
          :type="show3 ? 'text' : 'password'"
          :label="$t('ConfirmPassword')"
          @click:append="show3 = !show3"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          color="primary"
          @click="savePassword"
          :loading="is_saving_password"
          class="text-none mx-auto"
          >{{ $t("Button.Save") }}</v-btn
        >
      </v-card-actions>
    </v-form>

    <error-box class="ma-4" :error="error"></error-box>
  </div>
</template>

<i18n>
    {
    "en": {
    "ChangePassword": "Change password",
    "CurrentPassword": "Current password",
    "NewPassword": "New password",
    "ConfirmPassword": "Confirm password",
    "Required": "Required",
    "PasswordHint": "At least 8 characters",
    "SuccessUpdatePassword": "Password changed!",
    "PasswordNotMatch": "Password not match!",
    "PasswordUpdated": "Password updated!"
    },
    "sv": {
    "ChangePassword": "Byt lösenord",
    "CurrentPassword": "Nuvarande lösenord",
    "NewPassword": "Nya lösenordet",
    "ConfirmPassword": "Bekräfta lösenordet",
    "Required": "Måste anges",
    "PasswordHint": "Minst 8 tecken!",
    "SuccessUpdatePassword": "Lösenordet ändrat!",
    "PasswordNotMatch": "Lösenorden matchar inte!",
    "PasswordUpdated": "Lösenordet uppdaterades!"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "../../../_services";

export default {
  name: "account_home",
  metaInfo() {
    return {
      title: this.$t("ChangePassword"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    temp_user: {},
    error: null,

    password_form: false,
    password: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    is_saving_password: false,
    show1: false,
    show2: false,
    show3: false,

    rules: {},
  }),
  created: function () {
    Object.assign(this.temp_user, this.user);

    this.rules = {
      required: (value) => !!value || this.$t("Required"),
      min: (v) => v.length >= 8 || this.$t("PasswordHint"),
      passMatch: (value) => {
        return value === this.new_password || this.$t("PasswordNotMatch");
      },
    };
  },
  methods: {
    savePassword() {
      var self = this;
      self.error = null;

      if (self.$refs.password_form.validate()) {
        self.is_saving_password = true;

        accountService
          .changePassword(self.password)
          .then(function () {
            self.is_saving_password = false;
            self.$successNoty(self.$t("PasswordUpdated"));
          })
          .catch(function (error) {
            self.is_saving_password = false;
            self.error = error;
          });
      }
    },
  },
};
</script>