<template>
  <div class="message-form">
    <v-toolbar flat>
      <v-toolbar-title v-if="!error && page.id">{{
        $t("AnswerMessage")
      }}</v-toolbar-title>
      <v-toolbar-title v-if="!error && !page.id">{{
        $t("NewMessage")
      }}</v-toolbar-title>
    </v-toolbar>
    <v-skeleton-loader :loading="page.is_loading" type="article">
      <v-form ref="form" v-model="form" lazy-validation>
        <template v-if="!page.error">
          <v-card-text>
            <v-text-field
              :label="$t('Reciver')"
              :rules="[(v) => !!v || $t('ReciverIsMissing')]"
              v-model="reciver"
              :disabled="page.id != null"
              :loading="is_saving"
              outlined
              required
            ></v-text-field>
            <v-text-field
              :label="$t('Subject')"
              :rules="[(v) => !!v || $t('SubjectIsMissing')]"
              v-model="item.subject"
              :loading="is_saving"
              outlined
              required
            ></v-text-field>
            <v-textarea
              :loading="is_saving"
              required
              outlined
              :rules="[(v) => !!v || $t('MessageIsMissing')]"
              no-resize
              v-model="item.message"
              :label="$t('Message')"
            ></v-textarea>

            <error-box :error="error"></error-box>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="send"
              :loading="is_saving"
              class="text-none mx-auto"
              >{{ $t("Button.Send") }}</v-btn
            >
          </v-card-actions>
        </template>
        <error-page :error="page.error" v-if="error"></error-page>
      </v-form>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Message": "Message",
    "Subject": "Subject",
    "SubjectIsMissing": "Subject is missing!",
    "MessageIsMissing": "Message is missing!",
    "Reciver": "Reciver",
    "ReciverIsMissing": "Reciver is missing",
    "AnswerMessage": "Answer message",
    "NewMessage": "New message",
    "MessageSent": "Message sent!"
    },
    "sv": {
    "Message": "Meddelande",
    "Subject": "Ämne",
    "SubjectIsMissing": "Ämne saknas",
    "MessageIsMissing": "Meddelande saknas!",
    "Reciver": "Mottagare",
    "ReciverIsMissing": "Mottagare saknas",
    "AnswerMessage": "Svara meddelande",
    "NewMessage": "Nytt meddelande",
    "MessageSent": "Meddelandet skickades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { userMessageService } from "../../../_services";

export default {
  name: "account_messages",
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    item: {
      subject: "",
      message: "",
    },
    reciver: "",
    is_saving: false,
    error: null,
    form: false,

    page: {
      is_loading: false,
      error: null,
      id: null,
    },
  }),
  created: function () {
    if (this.$route.params.id) {
      this.loadMessage();
    }
  },
  methods: {
    ...mapActions({
      create: "account/messages/create",
    }),

    send() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .create({ reciver: self.reciver, message: self.item })
          .then(function () {
            self.$router.push("/account/messages");
            self.$successNoty(self.$t("MessageSent"));
            self.$refs.form.resetValidation();
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    loadMessage() {
      var self = this;
      self.page.error = null;
      self.page.is_loading = true;

      var id = self.$route.params.id;
      self.page.id = id;

      userMessageService
        .get(id)
        .then(function (response) {
          self.item.subject = "Re: " + response.data.subject;
          self.reciver = response.data.sender.username;

          self.item.message =
            "\n\n\n----- " + self.reciver + " -----\n" + response.data.body;
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },

    getMeta() {
      if (this.page.id)
        return {
          title: this.$t("AnswerMessage"),
        };
      else
        return {
          title: this.$t("NewMessage"),
        };
    },
  },
};
</script>