<template>
  <div class="account-connections">
    <v-card-title v-if="!page.error">
      <v-spacer></v-spacer>

      <button-menu
        text
        :items="admin_menu"
        color="primary"
        btn-icon-small
        btn-icon-append="$vuetify.icons.fasAngleDown"
        :btn-text="$t('Add')"
      ></button-menu>
    </v-card-title>

    <v-skeleton-loader
      :loading="page.is_loading"
      type="list-item-avatar-three-line"
    >
      <div>
        <template v-if="!page.error">
          <v-card-text>
            <error-box class="mt-3" :error="error"></error-box>
            <v-alert
              outlined
              type="info"
              prominent
              v-if="grouped && grouped.length > 1"
              class="mt-3"
              dismissible
              v-model="alert"
              close-text="Close Alert"
              border="left"
              >{{ $t("MultiConnections") }}</v-alert
            >

            <v-list
              two-line
              subheader
              v-if="grouped && grouped.length > 0"
              max-width="400"
              style="margin: auto"
            >
              <template v-for="(group, index) in grouped">
                <v-subheader :key="index" v-if="grouped.length > 1">{{
                  group.name
                }}</v-subheader>

                <v-list-item v-for="item in group.items" :key="item.id">
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-2">{{
                      getIcon(item.service)
                    }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.service"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <button-menu
                      icon
                      :items="getMenu(item)"
                      btn-icon="$vuetify.icons.dotsVertical"
                      :is-loading="item.is_deleting"
                    ></button-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>

            <div v-if="!grouped || grouped.length < 1" class="text-center mt-5">
              {{ $t("NoConnections") }}
            </div>
          </v-card-text>
        </template>
        <error-page :error="page.error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Connections": "Connections",
    "MultiConnections": "You have connections on both our sites, Barndagboken.se and Babiry.com. On this page you can only add / update connections that apply to Babiry.com. To add / update for Barndagboken, you must log in to Barndagboken.se.",
    "ConfirmDeleteConnection": "Are you sure you want to delete this connection?",
    "AddUpdateConnection": "Add / update connection",
    "SuccessAdd": "A new connection added!",
    "SuccessDelete": "Connection deleted!",
    "Add": "Add connection",
    "NoConnections": "There is no connections connected to your account."
    },
    "sv": {
    "Connections": "Anslutningar",
    "MultiConnections": "Du har anslutningar på båda våra sidor, Barndagboken.se och Babiry.com. På denna sida kan du enbart lägga till/uppdatera anslutningar som gäller för Barndagboken.se. För att lägga till/uppdatera för Babiry måste du logga in på Babiry.com.",
    "ConfirmDeleteConnection": "Är du säker på att du vill ta bort denna anslutning?",
    "AddUpdateConnection": "Lägg till / uppdatera anslutning",
    "SuccessAdd": "En ny anslutning lades till!",
    "SuccessDelete": "Anslutningen togs bort!",
    "Add": "Lägg till",
    "NoConnections": "Det finns inga kopplade konton till detta konto."
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "@/_services";
const HelloJs = require("hellojs/dist/hello.all.min.js");

export default {
  name: "account_connections",
  metaInfo() {
    return {
      title: this.$t("Connections"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    grouped() {
      var types = [];

      var babiry = this.items.filter(function (element) {
        return element.page == "Babiry";
      });
      var barndagboken = this.items.filter(function (element) {
        return element.page == "Barndagboken";
      });

      if (babiry && babiry.length > 0) {
        types.push({ name: "Babiry.com", items: babiry });
      }
      if (barndagboken && barndagboken.length > 0) {
        types.push({ name: "Barndagboken.se", items: barndagboken });
      }

      return types;
    },

    admin_menu: function () {
      var data = [];
      var self = this;

      data.push({
        name: "Facebook",
        icon: "$vuetify.icons.facebook",
        click: function () {
          self.addConnection("facebook");
        },
      });

      return data;
    },
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },
    alert: true,
    error: null,
    is_loading: false,
    items: [],
  }),
  created: function () {
    HelloJs.init(
      {
        facebook: process.env.VUE_APP_FACEBOOK_APP_ID,
      },
      {
        redirect_uri: "/redirect.html",
      }
    );

    this.init();
  },
  methods: {
    getMenu(item) {
      var data = [];
      var self = this;

      data.push({
        name: this.$t("Button.Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteConnection(item);
        },
      });

      return data;
    },
    init() {
      var self = this;
      self.page.error = null;
      self.page.is_loading = true;

      accountService
        .getProfile()
        .then(function (response) {
          self.items = response.data.social_connections;
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.is_loading = false;
          self.page.error = error;
        });
    },
    async deleteConnection(item) {
      var sender = this;

      const res = await sender.$confirm(sender.$t("ConfirmDeleteConnection"));
      sender.error = null;

      if (res) {
        item.is_deleting = true;
        accountService
          .deleteSocialConnection(item.id)
          .then(function () {
            sender.$successNoty(sender.$t("SuccessDelete"));
            sender.items = sender.items.filter((obj) => obj.id !== item.id);
          })
          .catch(function (error) {
            item.is_deleting = false;
            sender.error = error;
          });
      }
    },
    addConnection(network) {
      var self = this;
      if (self.is_loading) return;

      self.is_loading = true;

      self.error = null;

      HelloJs(network)
        .login({
          scope: "email",
        })
        .then(() => {
          const authRes = HelloJs(network).getAuthResponse();

          accountService
            .addSocialConnection({ type: network, token: authRes.access_token })
            .then(function (response) {
              var item = self.items.find(
                (element) => element.id == response.data.id
              );

              if (item) {
                item = response.data;
              } else {
                self.items.push(response.data);
              }
              self.is_loading = false;
              self.$successNoty(self.$t("SuccessAdd"));
            })
            .catch(function (error) {
              self.error = error;
              self.is_loading = false;
            });
        });
    },
    getIcon(service) {
      if (service == "Facebook") {
        return "$vuetify.icons.fabFacebook";
      }
      if (service == "Google") {
        return "$vuetify.icons.fabGoogle";
      }
      if (service == "Instagram") {
        return "$vuetify.icons.fabInstagram";
      }
    },
  },
};
</script>