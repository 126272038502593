<template>
  <span>
    <form-dialog
      v-model="is_open"
      :title="$t('Profileimage')"
      v-on:submit="save"
      max-width="550px"
      v-on:close="closing"
      :submit-disabled="avatar_processing || is_downloading"
      :is-saving="is_saving"
    >
      <template v-slot:content>
        <v-card
          outlined
          height="200"
          width="200"
          class="grey lighten-2 mx-auto mb-3"
        >
          <v-img
            :src="avatar"
            v-if="avatar"
            height="200"
            width="200"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-overlay :value="is_downloading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
        <div class="caption mb-3 help-text">{{ $t("CropInfo") }}</div>

        <div class="text-center">
          <v-btn
            outlined
            @click="pickfile"
            v-if="show_select"
            :loading="avatar_processing"
            :disabled="is_saving || is_downloading"
            small
            class="mr-1 mb-1 text-none"
          >
            <v-icon small left>$vuetify.icons.plus</v-icon>
            {{ $t("SelectImage") }}
          </v-btn>
          <v-btn
            v-if="diary && showAlbum"
            outlined
            @click="openAlbum"
            :loading="is_downloading"
            :disabled="is_saving || avatar_processing"
            small
            class="mr-1 mb-1 text-none"
          >
            <v-icon small left>$vuetify.icons.folderMultipleImage</v-icon>
            {{ $t("Album") }}
          </v-btn>
          <v-btn
            v-if="showGallery"
            outlined
            @click="openGallery"
            :loading="is_downloading"
            :disabled="is_saving || avatar_processing"
            small
            class="mr-1 mb-1 text-none"
          >
            <v-icon small left>$vuetify.icons.imageSearchOutline</v-icon>
            {{ $t("Gallery") }}
          </v-btn>
          <v-btn
            v-if="!$isMobile"
            outlined
            @click="editAvatar"
            :disabled="is_saving || avatar_processing || is_downloading"
            small
            class="mr-1 mb-1 text-none"
          >
            <v-icon small left>$vuetify.icons.pencil</v-icon>
            {{ $t("EditImage") }}
          </v-btn>
          <v-btn
            outlined
            @click="deleteAvatar"
            :disabled="is_saving || avatar_processing || is_downloading"
            small
            color="red"
            v-if="avatar && show_delete"
            class="mb-1 text-none"
            >{{ $t("DeleteImage") }}</v-btn
          >
        </div>
        <input
          type="file"
          style="display: none"
          ref="image"
          accept="image/*"
          v-on:change="onFilePicked"
        />

        <error-box :error="error" class="mt-3"></error-box>
      </template>

      <template v-if="diary || user" v-slot:footer>
        <v-icon>$vuetify.icons.earth</v-icon>
        <span class="caption pl-1 help-text help-public">
          {{ $t("ProfilePicturePublic") }}
        </span>
      </template>
    </form-dialog>

    <media-picker
      v-if="diary"
      v-on:done="selectedMedia"
      ref="mediaPicker"
      :diary="diary.name"
      :max="1"
    ></media-picker>
    <image-gallery-picker
      v-on:done="selectedGalleryImage"
      ref="galleryPicker"
      :max="1"
    ></image-gallery-picker>
    <photo-editor
      v-on:close="onEditAvatarClose"
      v-on:done="onEditAvatarDone"
      v-if="show_editor"
      :image="main_avatar"
    ></photo-editor>
  </span>
</template>

<i18n>
    {
    "en": {
    "Profileimage": "Profile picture",
    "SelectImage": "Select picture",
    "DeleteImage": "Delete",
    "EditImage": "Editor",
    "AvatarDeleted": "Avatar deleted!",
    "AvatarUploaded": "Avatar uploaded!",
    "Album": "Album",
    "Gallery": "Image gallery",
    "CropInfo": "If picture not match the container it will be croped by the system to fit the container.",
    "ProfilePicturePublic": "Your profile picture is public."
    },
    "sv": {
    "Profileimage": "Profilbild",
    "SelectImage": "Välj bild",
    "DeleteImage": "Ta bort",
    "EditImage": "Bildeditor",
    "AvatarDeleted": "Profilbilden togs bort!",
    "AvatarUploaded": "Profilbilden sparades!",
    "Album": "Album",
    "Gallery": "Bildgalleri",
    "CropInfo": "Om bilden inte täcker hela rutan kommer den automatiskt att beskäras av systemet vid uppladdningen.",
    "ProfilePicturePublic": "Din profilbild är publik."
    }
    }
</i18n>

<script>
import { mapActions } from "vuex";
import Compressor from "compressorjs";
import MediaPicker from "../diary/dialogs/MediaPickerDialog.vue";
import ImageGalleryPickerDialog from "../diary/dialogs/ImageGalleryPickerDialog.vue";
const PhotoEditor = () => import("../global/PhotoEditor.vue");

export default {
  name: "avatar_dialog",
  props: {
    showGallery: {
      type: Boolean,
      required: false,
    },
    showAlbum: {
      type: Boolean,
      required: false,
    },
  },

  components: {
    "media-picker": MediaPicker,
    "image-gallery-picker": ImageGalleryPickerDialog,
    "photo-editor": PhotoEditor,
  },
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,

    avatar_processing: false,
    is_downloading: false,

    avatar: null,
    main_avatar: null,
    file: null,
    image_id: null,

    avatar_type: null,

    child: null,
    user: null,
    diary: null,

    show_delete: true,
    show_select: true,
    show_editor: false,
  }),
  methods: {
    ...mapActions({
      deleteChildAvatar: "diary/children/deleteAvatar",
      uploadChildAvatar: "diary/children/uploadAvatar",

      uploadDiaryAvatar: "diary/uploadAvatar",
      deleteDiaryAvatar: "diary/deleteAvatar",

      uploadUserAvatar: "account/uploadAvatar",
      deleteUserAvatar: "account/deleteAvatar",
    }),

    closing() {
      this.error = null;
      this.is_saving = false;
      this.avatar_processing = false;
      this.is_downloading = false;
      this.avatar = null;
      this.main_avatar = null;
      this.file = null;
      this.show_editor = false;
      this.image_id = null;
    },

    openChildAvatar(diary, child) {
      this.child = child;
      this.diary = diary;

      if (this.child.avatar) {
        this.avatar = this.getAvatar(this.child.avatar);
        this.main_avatar = this.child.avatar.original;
      }

      this.avatar_type = "child";
      this.is_open = true;
    },
    openDiaryAvatar(diary) {
      this.diary = diary;

      if (this.diary.avatar) {
        this.avatar = this.getAvatar(this.diary.avatar);
        this.main_avatar = this.diary.avatar.original;
      }

      this.avatar_type = "diary";
      this.is_open = true;
    },
    openDiaryAvatarByUrl(diary, url, imageId) {
      this.openDiaryAvatar(diary);
      this.avatar = null;
      this.main_avatar = null;
      this.show_delete = false;
      this.show_select = false;
      this.image_id = imageId;
      this.selectByUrl(url);
    },
    openUserAvatar(user) {
      this.user = user;

      if (this.user.avatar) {
        this.avatar = this.getAvatar(this.user.avatar);
        this.main_avatar = this.user.avatar.original;
      }

      this.avatar_type = "user";
      this.is_open = true;
    },

    getAvatar(image) {
      if (typeof image === "object")
        return this.$imagehelpers.childAvatar(image);

      if (image.indexOf("blob") > -1) return image;

      return this.$imagehelpers.childAvatar(image, 200);
    },
    pickfile() {
      this.$refs.image.click();
    },
    openGallery() {
      this.$refs.galleryPicker.openAvatar();
    },
    onFilePicked(e) {
      var sender = this;

      const file = e.target.files[0];

      if (!file) {
        return;
      }
      sender.image_id = null;
      sender.avatar_processing = true;
      sender.error = null;

      new Compressor(file, {
        quality: 0.8,
        minWidth: 100,
        minHeight: 100,
        maxHeight: 800,
        maxWidth: 800,
        success(result) {
          var urlCreator = window.URL || window.webkitURL;
          var img = urlCreator.createObjectURL(result);

          sender.avatar = img;
          sender.main_avatar = img;
          sender.file = result; // this is an image file that can be sent to server...
          sender.avatar_processing = false;
        },
        error(err) {
          sender.error = err.message;
          sender.avatar_processing = false;
        },
      });
    },

    deleteAvatar() {
      this.file = null;
      this.avatar = null;
      this.main_avatar = null;
      this.image_id = null;
    },
    editAvatar() {
      this.show_editor = true;
    },
    onEditAvatarDone(data) {
      this.file = data.blob;
      this.avatar = data.dataUrl;
      this.main_avatar = data.dataUrl;
      this.show_editor = false;
    },
    onEditAvatarClose() {
      this.show_editor = false;
    },
    openAlbum() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      if (items.length > 0) {
        var img = items[0];
        this.selectByUrl(img.image.original);
        this.image_id = img.id;
      }
    },
    selectedGalleryImage(url) {
      if (!url) return;
      this.selectByUrl(url);
      this.image_id = null;
    },
    selectByUrl(url) {
      var self = this;
      self.is_downloading = true;
      self.error = null;

      this.$imagehelpers
        .getImageFormUrl(url)
        .then(function (response) {
          self.file = response;

          var urlCreator = window.URL || window.webkitURL;
          self.avatar = urlCreator.createObjectURL(response);
          self.main_avatar = self.avatar;

          self.is_downloading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_downloading = false;
        });
    },
    save() {
      var self = this;
      self.error = null;

      if (this.avatar_type == "child") {
        this.saveChildAvatar();
      } else if (this.avatar_type == "diary") {
        this.saveDiaryAvatar();
      } else if (this.avatar_type == "user") {
        this.saveUserAvatar();
      }
    },
    saveChildAvatar() {
      var self = this;

      if (self.file) {
        self.is_saving = true;

        self
          .uploadChildAvatar({ id: self.child.id, file: self.file })
          .then(function (response) {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarUploaded"));
            self.$emit("uploaded", response.data);
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      } else if (!self.avatar && self.child.avatar != null) {
        self.is_saving = true;

        self
          .deleteChildAvatar(self.child.id)
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarDeleted"));
            self.$emit("deleted");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    saveDiaryAvatar() {
      var self = this;

      if (self.file) {
        self.is_saving = true;

        self
          .uploadDiaryAvatar({ file: self.file, image_id: self.image_id })
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarUploaded"));
            self.$emit("uploaded");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      } else if (!self.avatar && self.diary.avatar != null) {
        self.is_saving = true;

        self
          .deleteDiaryAvatar()
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarDeleted"));
            self.$emit("deleted");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    saveUserAvatar() {
      var self = this;

      if (self.file) {
        self.is_saving = true;

        self
          .uploadUserAvatar(self.file)
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarUploaded"));
            self.$emit("uploaded");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      } else if (!self.avatar && self.user.avatar != null) {
        self.is_saving = true;

        self
          .deleteUserAvatar()
          .then(function () {
            self.is_open = false;
            self.is_saving = false;
            self.$successNoty(self.$t("AvatarDeleted"));
            self.$emit("deleted");
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.help-text {
  font-size: 14px !important;
}
.help-public {
  padding-top: 2px;
  color: gray;
}
</style>