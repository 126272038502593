<template>
  <v-list>
    <v-list-item-group color="primary">
      <template v-for="(item, i) in menuItems">
        <template v-if="!item.divider">
          <v-list-item :to="item.url" :key="i" :exact="item.exact">
            <v-list-item-avatar class="ml-n1">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="ml-n1">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'div' + i" v-if="item.show_divider"></v-divider>
        </template>
        <v-divider :key="'divs' + i" v-if="item.divider"></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "menu_list",

  props: {
    menuItems: {
      type: Array,
    },
  },
};
</script>