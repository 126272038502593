<template>
  <div class="fill-height">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("AccountSettings") }}</v-toolbar-title>

      <template v-slot:extension>
        <v-tabs
          class="mobile-tabs-fix tab-border-all-fix"
          active-class="active-menu"
          hide-slider
          :grow="$isMobile"
        >
          <v-tab
            v-for="(item, i) in menu"
            exact-path
            :to="item.url"
            :key="i"
            class="text-none"
            >{{ item.title }}</v-tab
          >
        </v-tabs>
      </template>
    </v-toolbar>

    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<i18n>
    {
    "en": {
      "AccountSettings": "Account settings",
      "Avatar": "Avatar",
      "ChangePassword": "Password",
      "Connections": "Connections",
      "Email": "Email",
      "General": "General"
    },
    "sv": {
      "AccountSettings": "Kontoinställningar",
      "Avatar": "Profilbild",
      "ChangePassword": "Lösenord",
      "Connections": "Anslutningar",
      "Email": "Email",
      "General": "Allmänt"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";

export default {
  name: "account_profile_master",
  metaInfo() {
    return {
      title: this.$t("AccountSettings"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    menu: [],
  }),
  created: function () {
    if (!this.user) {
      this.$router.push("/login");
      return;
    }
    this.menu = [
      {
        title: this.$t("General"),
        icon: "$vuetify.icons.fasCog",
        url: { name: "account_home" },
        exact: true,
      },
      {
        title: this.$t("Email"),
        icon: "$vuetify.icons.fasEnvelope",
        url: { name: "account_email" },
      },
      {
        title: this.$t("Avatar"),
        icon: "$vuetify.icons.fasUserCircle",
        url: { name: "account_avatar" },
      },
      {
        title: this.$t("ChangePassword"),
        icon: "$vuetify.icons.fasKey",
        url: { name: "account_password" },
      },
      {
        title: this.$t("Connections"),
        icon: "$vuetify.icons.fasGlobe",
        url: { name: "account_connections" },
      },
    ];
  },
};
</script>