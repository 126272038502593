<template>
  <div class="notification-page">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Notifications") }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <button-menu
        depressed
        :items="menu"
        btn-icon="$vuetify.icons.dotsHorizontal"
        :is-loading="isSetAllReaded"
        v-if="items != null && items.length > 0"
      ></button-menu>
    </v-toolbar>

    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <template v-if="items && items.length > 0">
            <v-list class="mt-5 notification-list">
              <v-divider></v-divider>
              <template v-for="(item, index) in items">
                <v-list-item
                  :key="item.id"
                  v-bind:class="{ unreaded: !item.is_read }"
                >
                  <v-list-item-avatar
                    class="mr-2 pointer"
                    @click="goTo(item.url)"
                  >
                    <v-icon class="grey lighten-2">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content
                    @click="goTo(item.url)"
                    class="pointer notification-text"
                  >
                    <div v-html="item.text"></div>
                    <v-list-item-subtitle class="global-list-desc">{{
                      item.time_ago
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <button-menu
                      icon
                      :items="getItemMenu(item)"
                      btn-icon="$vuetify.icons.dotsVertical"
                      :is-loading="item.is_deleting"
                    ></button-menu>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list>

            <div class="text-center mt-7 mb-12" v-if="has_more">
              <v-btn
                :loading="is_loading_more"
                @click="load"
                color="primary"
                block
                text
                class="text-none"
                >{{ $t("LoadMore") }}</v-btn
              >
            </div>
          </template>
          <template v-else>
            <div class="text-center mt-5 mb-5">{{ $t("NoMessages") }}</div>
          </template>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Notifications": "Notifications",
    "NoMessages": "No notifications to show!",
    "Delete": "Delete notification",
    "SuccessDelete": "Notification deleted!",
    "LoadMore": "Load more",
    "SetAsReaded": "Mark as readed",
    "SetAsUnreaded": "Mark as unreaded",
    "SetAllReaded": "Mark all as readed"
    },
    "sv": {
    "Notifications": "Aviseringar",
    "NoMessages": "Det finns inga aviseringar att visa!",
    "Delete": "Ta bort aviseringen",
    "SuccessDelete": "Aviseringen togs bort!",
    "LoadMore": "Visa flera",
    "SetAsReaded": "Markera som läst",
    "SetAsUnreaded": "Markera som oläst",
    "SetAllReaded": "Markera alla som lästa"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { userNotifications } from "@/_services";
import { notificationCreator } from "@/_helpers";

export default {
  name: "account_notifications",
  metaInfo() {
    return {
      title: this.$t("Notifications"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      is_all_updating: (state) => state.account.notifications.is_all_updating,
    }),
    menu: function () {
      var data = [];

      data.push({
        name: this.$t("SetAllReaded"),
        icon: "$vuetify.icons.messageAlertOutline",
        click: this.setAllReaded,
      });

      return data;
    },
  },
  data: () => ({
    is_loading: false,
    is_loading_more: false,
    error: null,
    page: 0,
    has_more: false,
    items: [],

    isSetAllReaded: false,
  }),
  created: function () {
    this.load();
  },
  methods: {
    ...mapActions({
      deleteNotification: "account/notifications/delete",
      dbSetUnReaded: "account/notifications/setUnReaded",
      dbSetReaded: "account/notifications/setReaded",
      dbSetAllReaded: "account/notifications/setAllReaded",
    }),
    getItemMenu(item) {
      var data = [];
      var self = this;

      if (!item.is_read)
        data.push({
          name: this.$t("SetAsReaded"),
          icon: "$vuetify.icons.messageAlertOutline",
          click: function () {
            self.setReaded(item);
          },
        });
      else
        data.push({
          name: this.$t("SetAsUnreaded"),
          icon: "$vuetify.icons.messageAlert",
          click: function () {
            self.setUnReaded(item);
          },
        });

      data.push({
        name: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteAction(item);
        },
      });

      return data;
    },
    load() {
      var self = this;
      self.error = null;

      if (self.page == 0) {
        self.is_loading = true;
      } else {
        self.is_loading_more = true;
      }

      userNotifications
        .inbox(self.page + 1)
        .then(function (response) {
          self.is_loading = false;
          self.is_loading_more = false;
          self.items = self.items.concat(
            notificationCreator.formatedNotifications(response.data.data)
          );
          self.has_more = response.data.has_more;
          self.page = response.data.current_page;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
          self.is_loading_more = false;
        });
    },
    deleteAction(item) {
      var self = this;
      self.$set(item, "is_deleting", true);

      self
        .deleteNotification(item.id)
        .then(function () {
          self.items = self.items.filter((post) => post.id !== item.id);
          self.$successNoty(self.$t("SuccessDelete"));
        })
        .catch(function (error) {
          item.is_deleting = false;
          self.$ajaxErrorNoty(error);
        });
    },
    getMeta() {
      var obj = {
        title: this.$t("Messages"),
      };
      return obj;
    },
    goTo(url) {
      this.$router.push(url);
    },
    setReaded(item) {
      var self = this;
      self.$set(item, "is_updating", true);

      self
        .dbSetReaded(item.id)
        .then(function () {
          self.$set(item, "is_updating", false);
          item.is_read = true;
        })
        .catch(function (error) {
          self.$set(item, "is_updating", false);
          self.$ajaxErrorNoty(error);
        });
    },
    setAllReaded() {
      var self = this;
      self.isSetAllReaded = true;

      self
        .dbSetAllReaded()
        .then(function () {
          for (var i = 0; i < self.items.length; i++) {
            self.items[i].is_read = true;
          }
          self.isSetAllReaded = false;
        })
        .catch(function (error) {
          self.isSetAllReaded = false;
          self.$ajaxErrorNoty(error);
        });
    },
    setUnReaded(item) {
      var self = this;

      self.$set(item, "is_updating", true);

      self
        .dbSetUnReaded(item.id)
        .then(function () {
          self.$set(item, "is_updating", false);
          item.is_read = false;
        })
        .catch(function (error) {
          self.$set(item, "is_updating", false);
          self.$ajaxErrorNoty(error);
        });
    },
  },
  watch: {},
};
</script>

<style>
.notification-page .notification-text {
  font-size: 15px !important;
}

.notification-page .notification-text span.user,
.notification-page .notification-text span.blog,
.notification-page .notification-text span.post,
.notification-page .notification-text span.media {
  font-weight: 500 !important;
}
</style>