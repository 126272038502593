<template>
  <div class="user-following pb-15">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Following") }}</v-toolbar-title>
    </v-toolbar>

    <v-skeleton-loader
      :loading="is_loading"
      max-width="500"
      type="list-item-two-line"
    >
      <template v-if="!error">
        <v-list v-if="items && items.length > 0" three-line>
          <template v-for="item in items">
            <v-divider :key="'d' + item.id"></v-divider>
            <v-list-item :key="item.id">
              <v-list-item-avatar :size="50">
                <user-avatar :user="item" :size="50"></user-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  <router-link :to="'/' + item.name">
                    {{ item.title | capitalize }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle
                  >{{ $t("Added") }} {{ formatDate(item.created) }}<br />
                  <v-btn
                    class="mt-1 text-none"
                    outlined
                    x-small
                    color="primary"
                    v-if="!item.is_accepted"
                    @click="dialog = true"
                  >
                    {{ $t("Pending") }}
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <button-menu
                  icon
                  :items="getMenu(item)"
                  btn-icon="$vuetify.icons.dotsVertical"
                  :is-loading="item.is_deleting"
                ></button-menu>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-divider></v-divider>
        </v-list>

        <v-col cols="12" class="text-center" v-if="unit_pages > 1">
          <v-pagination
            v-model="page"
            :length="unit_pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>

        <template v-if="items.length < 1">
          <div class="text-center mt-5 mb-5">{{ $t("NoFollowing") }}</div>
        </template>
      </template>
      <error-page :error="error" v-if="error"></error-page>
    </v-skeleton-loader>

    <form-dialog
      v-model="dialog"
      :title="$t('Pending')"
      v-on:submit="dialog = false"
      :submit-text="$t('Ok')"
    >
      <template v-slot:content>
        {{ $t("PendingDesc") }}
      </template>
    </form-dialog>
  </div>
</template>

<i18n>
    {
    "en": {
    "Following": "Following",
    "NoFollowing": "No following diaries to display!",
    "Unfollow": "Unfollow",
    "Added": "Added",
    "SuccessUnfollow": "Unfollowed diary!",
    "CancelRequest": "Cancel request",
    "Pending": "Pending request",
    "PendingDesc": "The owner of the diary must approve your request to follow the diary.",
    "Ok": "Ok"
    },
    "sv": {
    "Following": "Följer",
    "NoFollowing": "Användaren följer inga dagböcker!",
    "Unfollow": "Avfölj",
    "Added": "Lades till den",
    "SuccessUnfollow": "Avföljde dagboken!",
    "CancelRequest": "Avbryt förfrågan",
    "Pending": "Inväntar godkännande",
    "PendingDesc": "Dagbokens ägare måste godkänna din ansökan för att följa dagboken.",
    "Ok": "Ok"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService, diaryService } from "@/_services";
import * as moment from "moment";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "user_following",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  metaInfo() {
    return this.getMeta();
  },
  components: {
    "user-avatar": UserAvatar,
  },
  data: () => ({
    items: [],
    unit_pages: 0,
    page: 1,
    is_loading: false,
    error: null,
    is_owner: false,
    dialog: false,
  }),
  created: function () {
    this.setFromUrl();
    this.loadData();
  },

  methods: {
    getMenu(item) {
      var data = [];
      var self = this;

      if (item.is_accepted)
        data.push({
          name: this.$t("Unfollow"),
          click: function () {
            self.unfollow(item);
          },
        });
      else
        data.push({
          name: this.$t("CancelRequest"),
          click: function () {
            self.unfollow(item);
          },
        });

      return data;
    },
    loadData() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      accountService
        .getMyFollowing(self.page)
        .then(function (response) {
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.is_loading = false;
          self.error = error;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    getMeta() {
      var obj = {
        title: this.$t("Following"),
      };
      return obj;
    },
    unfollow(item) {
      var self = this;
      item.is_deleting = true;

      diaryService
        .unfollow(item.name)
        .then(function () {
          if (self.unit_pages > 1) {
            self.loadData();
          } else {
            for (var i = 0; i < self.items.length; i++) {
              if (self.items[i].name == item.name) {
                self.items.pop(i);
                return;
              }
            }
          }
          self.$successNoty(self.$t("SuccessUnfollow"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          item.is_deleting = false;
        });
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadData();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });

      self.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
a {
  color: black !important;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>