<template>
  <div class="fill-height default-bg">
    <v-container
      class="pt-3 mb-12 pb-12 account-master mobile-padding-fix"
      v-if="user"
    >
      <v-row class="mobile-margin-fix mt-0">
        <v-col
          xs="12"
          md="3"
          cols="12"
          class="mobile-padding-fix"
          v-if="!$isMobile"
        >
          <v-card>
            <v-list-item two-line>
              <v-list-item-avatar :size="50">
                <user-avatar :user="user" :size="50"></user-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  user.username | capitalize
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("LoggedIn")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <menu-list :menu-items="menu"></menu-list>
          </v-card>
        </v-col>
        <v-col xs="12" md="9" cols="12" class="account-main mobile-padding-fix">
          <v-card class="pb-3" min-height="500">
            <router-view :key="$route.fullPath"></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
      "Account": "My account",
      "LoggedIn": "Logged In",
      "AccountSettings": "Account settings",
      "Avatar": "Avatar",
      "ChangePassword": "Password",
      "Connections": "Connections",
      "Messages": "Messages",
      "Notifications": "Notifications",
      "Email": "Email",
      "LogOut": "Sign out",
      "General": "General",
      "Following": "Following"
    },
    "sv": {
      "Account": "Mitt konto",
      "LoggedIn": "Inloggad",
      "AccountSettings": "Kontoinställningar",
      "Avatar": "Profilbild",
      "ChangePassword": "Lösenord",
      "Connections": "Anslutningar",
      "Messages": "Meddelanden",
      "Notifications": "Aviseringar",
      "Email": "Email",
      "LogOut": "Logga ut",
      "General": "Allmänt",
      "Following": "Följer"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import MenuList from "@/components/helpers/menu/MenuList.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";

export default {
  name: "account_master",
  metaInfo() {
    return {
      title: this.$t("Account"),
    };
  },
  components: {
    "menu-list": MenuList,
    "user-avatar": UserAvatar,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    menu: [],
  }),
  created: function () {
    if (!this.user) {
      this.$router.push("/login");
      return;
    }
    this.menu = [
      {
        title: this.$t("AccountSettings"),
        icon: "$vuetify.icons.fasCog",
        url: { name: "account_home" },
        exact: false,
      },
      {
        title: this.$t("Messages"),
        icon: "$vuetify.icons.fasComments",
        url: { name: "account_messages" },
      },
      {
        title: this.$t("Notifications"),
        icon: "$vuetify.icons.fasBell",
        url: { name: "account_notifications" },
      },
      {
        title: this.$t("Following"),
        icon: "$vuetify.icons.fasStar",
        url: { name: "account_following" },
      },
      { divider: true },
      {
        title: this.$t("LogOut"),
        icon: "$vuetify.icons.fasSignOutAlt",
        url: { name: "account_logout" },
      },
    ];
  },
};
</script>