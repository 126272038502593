<template>
  <div class="account-home pt-5">
    <v-form ref="settings_form" v-model="settings_form" lazy-validation>
      <v-card-text>
        <v-text-field
          v-model="temp_user.username"
          disabled
          :label="$t('Username')"
          outlined
          persistent-hint
          :hint="$t('HintUserName')"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="temp_user.name"
          :disabled="is_saving"
          :label="$t('Name')"
          outlined
          :placeholder="$t('Optional')"
        ></v-text-field>

        <v-select
          :items="private_status_items"
          outlined
          v-model="temp_user.private_status"
          :disabled="is_saving"
          item-text="name"
          item-value="value"
          :label="$t('Privacy')"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="saveSettings"
          class="text-none mx-auto"
          :loading="is_saving"
          >{{ $t("Button.Save") }}</v-btn
        >
      </v-card-actions>
    </v-form>
    <error-box class="ma-4" :error="error"></error-box>
  </div>
</template>

<i18n>
    {
    "en": {
    "Account": "Account settings",
    "Privacy": "Privacy",
    "OpenForAll": "Open for all",
    "OnlyAuthorizedUsers": "Only authorized users",
    "PrivateProfile": "Private profile",
    "SuccessUpdateSettings": "Settings saved!",
    "Name": "Name",
    "Required": "Required",
    "Username": "Username",
    "Optional": "Optional",
    "HintUserName": "Username can't be changed"
    },
    "sv": {
    "Account": "Kontoinställningar",
    "Privacy": "Privacy",
    "OpenForAll": "Öppen för alla",
    "OnlyAuthorizedUsers": "Enbart öppen för inloggade",
    "PrivateProfile": "Privat / stängd profil",
    "SuccessUpdateSettings": "Inställningarna sparades!",
    "Name": "Namn",
    "Required": "Måste anges",
    "Username": "Användarnamn",
    "Optional": "Frivillig",
    "HintUserName": "Användarnamnet går inte att ändra"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "account_home",
  metaInfo() {
    return {
      title: this.$t("Account"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    settings_form: false,
    is_saving: false,
    private_status_items: [],
    error: null,

    temp_user: {},
  }),
  created: function () {
    this.private_status_items = [
      { name: this.$t("OpenForAll"), value: 0 },
      { name: this.$t("OnlyAuthorizedUsers"), value: 1 },
      { name: this.$t("PrivateProfile"), value: 2 },
    ];
    Object.assign(this.temp_user, this.user);
  },
  methods: {
    ...mapActions({
      updateProfile: "account/updateProfile",
    }),
    saveSettings() {
      var sender = this;
      sender.error = null;

      if (sender.$refs.settings_form.validate()) {
        sender.is_saving = true;

        sender
          .updateProfile({
            name: sender.temp_user.name,
            private_status: sender.temp_user.private_status,
          })
          .then(function () {
            sender.is_saving = false;
            sender.$successNoty(sender.$t("SuccessUpdateSettings"));
          })
          .catch(function (error) {
            sender.is_saving = false;
            sender.error = error;
          });
      }
    },
  },
};
</script>