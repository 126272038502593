<template>
  <form-dialog
    v-model="is_open"
    :title="title"
    v-on:submit="done"
    :submit-text="$t('Button.Done')"
    max-width="800px"
    content-class="max-400"
    :submit-disabled="!selected_medias || selected_medias.length == 0"
  >
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col xs="6" md="3" cols="12" v-for="item in images" :key="item.url">
            <v-card
              height="150"
              @click="imageClick(item)"
              :class="{ 'selected-media': isSelected(item) }"
            >
              <v-img :src="item.url" height="150" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "SelectImages": "Select images",
    "SelectImage": "Select image"
    },
    "sv": {
    "SelectImages": "Välj bilder",
    "SelectImage": "Välj bild"
    }
    }
</i18n>

<script>
export default {
  name: "image_gallery_picker_dialog",
  props: {
    max: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    is_open: false,
    images: [],

    selected_medias: [],

    height: "",
  }),
  computed: {
    title: function () {
      if (this.max > 1) {
        return this.$t("SelectImages");
      } else return this.$t("SelectImage");
    },
  },
  methods: {
    open() {
      this.height = "height:" + window.innerHeight / 2 + "px";
      this.images = this.getData();
      this.is_open = true;
    },
    openAvatar() {
      this.height = "height:" + window.innerHeight / 2 + "px";
      this.images = this.getAvatars();
      this.is_open = true;
    },
    getData() {
      return [
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/animals-2222007_960_720.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/animals-2739386_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/animals-3017138_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/animals-3779702_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/ballet-shoes-2326987_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/banner-1079234_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/banner-1176676_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/banner-1686943_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/christmas-2992358_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/ducks-1426010_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/emotions-3459666_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/logo-1862306_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/mountains-3187581_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/nature-2531761_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/nature-3804722_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/sunset-1645103_1280.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/banner/travel-3135436_1280.jpg",
        },
      ];
    },
    getAvatars() {
      return [
        {
          url: "https://cdn.babiry.com/web/gallery/avatar/always_true_love.jpg",
        },
        { url: "https://cdn.babiry.com/web/gallery/avatar/blue_cheetah.gif" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/cute_squirrel.jpg" },
        {
          url:
            "https://cdn.babiry.com/web/gallery/avatar/dark_haired_woman.jpg",
        },
        { url: "https://cdn.babiry.com/web/gallery/avatar/ducks_and_cat.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/fox.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/giraffies_love.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/goldfish_kiss.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/hamsters_kiss.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/in_sky.jpg" },
        {
          url:
            "https://cdn.babiry.com/web/gallery/avatar/kitty_piggy_rabbit_chicks_young.jpg",
        },
        { url: "https://cdn.babiry.com/web/gallery/avatar/Love_Sheep.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/modigliani.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/nature_cheetah.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/orca.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/penguin_chick.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/penguin_family.gif" },
        {
          url:
            "https://cdn.babiry.com/web/gallery/avatar/so_tiny_so_pretty.jpg",
        },
        {
          url:
            "https://cdn.babiry.com/web/gallery/avatar/squirrel_chiken_love.jpg",
        },
        { url: "https://cdn.babiry.com/web/gallery/avatar/squirrels_kiss.jpg" },
        { url: "https://cdn.babiry.com/web/gallery/avatar/the.jpg" },
        {
          url: "https://cdn.babiry.com/web/gallery/avatar/the_artists_wife.jpg",
        },
        { url: "https://cdn.babiry.com/web/gallery/avatar/white_lamb.jpg" },
        {
          url:
            "https://cdn.babiry.com/web/gallery/avatar/young_woman_modigliani.jpg",
        },
      ];
    },
    isSelected(item) {
      return (
        this.selected_medias.filter((element) => element.url == item.url) != ""
      );
    },
    imageClick(image) {
      var self = this;

      if (self.isSelected(image)) {
        self.selected_medias = [
          ...self.selected_medias.filter(
            (element) => element.url !== image.url
          ),
        ];
      } else {
        if (self.selected_medias.length >= self.max) {
          self.selected_medias.pop();
        }

        self.selected_medias.push(image);
      }
    },
    done() {
      for (var i = 0; i < this.selected_medias.length; i++)
        this.selected_medias[i] = this.selected_medias[i].url;

      this.$emit("done", this.selected_medias);
      this.is_open = false;
    },
  },
};
</script>

<style scoped>
.selected-media {
  border: solid 2px green;
  opacity: 0.5;
}
.selected-media .v-image {
  height:146px !important;
}
</style>