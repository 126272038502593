<template>
  <div class="account-avatar">
    <v-card-text class="text-center">
      <div class="mt-5">
        <user-avatar :size="150" :user="user"></user-avatar>
      </div>

      <div class="mt-3 pb-5">
        <v-btn
          color="primary"
          dark
          @click="openAvatar"
          class="mr-2 text-none"
          small
          >{{ $t("HandleAvatar") }}</v-btn
        >
      </div>
    </v-card-text>
    <avatar-dialog show-gallery ref="avatarDialog"></avatar-dialog>
  </div>
</template>

<i18n>
    {
    "en": {
    "Avatar": "Avatar",
    "HandleAvatar": "Change avatar"
    },
    "sv": {
    "Avatar": "Profilbild",
    "HandleAvatar": "Byt avatar"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import AvatarDialog from "../../../components/dialogs/AvatarDialog.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "account_avatar",
  metaInfo() {
    return {
      title: this.$t("Avatar"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  components: {
    "avatar-dialog": AvatarDialog,
    "user-avatar": UserAvatar,
  },
  created: function () {
    if (!this.user) {
      this.$router.push("/login");
      return;
    }
  },
  methods: {
    openAvatar() {
      this.$refs.avatarDialog.openUserAvatar(this.user);
    },
  },
};
</script>