<template>
  <div>
    <v-skeleton-loader :loading="is_loading" type="article">
      <div>
        <template v-if="!error && !is_loading">
          <v-card-text>
            <div class="text-center mb-5">
              <user-avatar :user="item.sender" :size="150"></user-avatar>

              <h1 class="title mt-2">
                {{ item.sender.username | capitalize }}
              </h1>
            </div>

            <v-divider></v-divider>

            <h2 class="pt-3">{{ item.subject | capitalize }}</h2>
            <div class="text-fix mb-4">{{ item.body | capitalize }}</div>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="is_deleting"
              color="primary"
              text
              class="text-none"
              :to="'/account/messages/' + item.id + '/answer'"
              >{{ $t("Answer") }}</v-btn
            >
            <v-btn
              :loading="is_deleting"
              color="error"
              text
              @click="deleteItem"
              class="text-none"
              >{{ $t("Button.Delete") }}</v-btn
            >
          </v-card-actions>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Messages": "Recived messages",
    "Answer": "Answer",
    "ConfirmDeleteMessage": "Are you sure you want to delete this message?"
    },
    "sv": {
    "Messages": "Mottaget meddelanden",
    "Answer": "Svara",
    "ConfirmDeleteMessage": "Är du säker att du vill ta bort detta meddelande?"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { userMessageService } from "../../../_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "account_messages",
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  components: {
    "user-avatar": UserAvatar,
  },
  data: () => ({
    item: {},
    is_loading: false,
    is_deleting: false,
    error: null,
  }),
  created: function () {
    this.loadMessage();
  },
  methods: {
    ...mapActions({
      delete: "account/messages/delete",
      setIsReadMessage: "account/messages/setIsReadMessage",
    }),
    loadMessage() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      var id = self.$route.params.id;

      userMessageService
        .get(id)
        .then(function (response) {
          self.is_loading = false;
          self.item = response.data;
          self.setIsReadMessage(id);
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    getMeta() {
      var obj = {
        title: this.$t("Messages"),
      };
      return obj;
    },
    async deleteItem() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteMessage"));

      if (res) {
        self.is_deleting = true;
        self
          .delete(self.item.id)
          .then(function () {
            self.$router.push("/account/messages");
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_deleting = false;
          });
      }
    },
  },
};
</script>